import { DocumentReference } from "firebase/firestore";

export class City {
    id?: string;
    ref?: DocumentReference;
    name: string = '';
	nameTranslatedGerman: string = '';

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export interface CityOption {
	id: string;
	label: string;
	value: City;
}

export function getCityFormattedByRef(ref: DocumentReference | null, cities: City[]): string {
    let cityString = '';
    for (const city of cities) {
        if (city.id === ref?.id) {
            cityString = city.nameTranslatedGerman;
        }
    }
    return cityString;
}