import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import CoffeeshopEditor from './CoffeeshopEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { Coffeeshop } from '@models/Coffeeshop';
import { City } from '@models/City';
import CoffeeshopsTable from './CoffeeshopsTable';

interface Props {
	dialog: ConfirmDialog;
}

function Coffeeshops(props: Props) {
	const [coffeeshops, setCoffeeshops] = useState<Coffeeshop[]>([]);
	const [cities, setCities] = useState<City[]>([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [coffeeshopToEdit, setCoffeeshopToEdit] = useState<Coffeeshop | null>(null);

	useEffect(() => {
		return onSnapshot(collection(db, 'coffeeshops'), (snapshot) => {
			const coffeeshops: Coffeeshop[] = []
			snapshot.forEach(document => {
				const documentData = document.data();

				const coffeeshop: Coffeeshop = {
					...(documentData as Coffeeshop),
					id: document.id
				};

				coffeeshops.push(coffeeshop);
			});
			setCoffeeshops(coffeeshops);
		});
	}, []);

	useEffect(() => {
		return onSnapshot(collection(db, 'cities'), (snapshot) => {
			const cities: City[] = [];
			snapshot.forEach(document => {
				const documentData = document.data();
				const city: City = {
					...(documentData as City),
					id: document.id,
					ref: document.ref
				};

				cities.push(city);
			});
			setCities(cities);
		});
	}, []);

	function handleNewCoffeeshop(): void {
		setCoffeeshopToEdit(null);
		setEditorOpen(true);
	}

	function handleEditCoffeeshop(coffeeshop: Coffeeshop): void {
		setCoffeeshopToEdit(coffeeshop);
		setEditorOpen(true);
	}

	async function handleDeleteCoffeeshop(coffeeshop: Coffeeshop): Promise<void> {
		const { dialog } = props;

		dialog.confirm({
			title: 'Coffeeshop Löschen',
			message: `Soll der Coffeshop "${coffeeshop.name}" wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'error',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'primary',
				variant: 'text'
			}
		}).then((): void => {
			if (coffeeshop.id) {
				deleteDoc(doc(db, 'coffeeshops', coffeeshop.id)).then((): void => {
						return;
					}).catch((error): void => {
						console.log('Failed to delete coffeeshop: ' + error);
					});
			} else {
				throw new Error('Missing ID');
			}
		}).catch((error: Error): void => {
			if (error) {
				console.log(error);
			}
		});
	}

	function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">Coffeeshops</Headline>
			<CoffeeshopsTable 
				coffeeshops={coffeeshops}
				cities={cities}
				handleEditCoffeeshop={handleEditCoffeeshop}
				handleDeleteCoffeeshop={handleDeleteCoffeeshop}
			/>
			<CoffeeshopEditor
				editorOpen={editorOpen}
				closeHandler={handleCloseEditor}
				coffeeshop={coffeeshopToEdit}
				cities={cities}
			/>
			<AddButton
				color="primary"
				aria-label="add"
				onClick={() => {handleNewCoffeeshop();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));

export default withDialog()(Coffeeshops);
