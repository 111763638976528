import {
	Paper
} from '@mui/material';
import { styled } from '@mui/system';
import { auth } from '../../../firebase';

function Dashboard() {
	return <RootPaper style={{marginLeft: '290px'}}>Angemeldet mit: <b>{auth.currentUser && auth.currentUser.email}</b></RootPaper>;
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

export default Dashboard;
