import { Subbundle } from "@models/Subbundle";

export class Bundle {
    id?: string;
    name: string = '';
    description: string = '';
    descriptionTranslatedGerman: string = '';
    price: number = 0;
    active: boolean = false;
    subbundles: Subbundle[] = [];

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}