import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import BundleEditor from './BundleEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { Bundle } from '@models/Bundle';

interface Props {
	dialog: ConfirmDialog;
}

function Bundles(props: Props) {
    const [editorOpen, setEditorOpen] = useState(false);
    const [bundleToEdit, setBundleToEdit] = useState<Bundle | null>(null);

    function handleNewBundle(): void {
		setBundleToEdit(null);
		setEditorOpen(true);
	}

    function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">{'Bundles'}</Headline>
			<BundleEditor
				editorOpen={editorOpen}
				closeHandler={handleCloseEditor}
				bundle={bundleToEdit}
			/>
			<AddButton
				color="primary"
				onClick={() => {handleNewBundle();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));

export default withDialog()(Bundles);
