import { useLocation, Link } from 'react-router-dom';
import {
	Button,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import LogoutIcon from '@mui/icons-material/Logout';

function Menu() {
	const location = useLocation();

	return (
		<>
			{(location.pathname !== '/' && location.pathname !== '/admin-panel/login') &&
			<>
				<Drawer
					variant="permanent"
					sx={{
						width: 290,
						[`& .MuiDrawer-paper`]: { width: 290 },
					}}
					>
						<DrawerHeader>
							<Button
								component={Link}
								variant="text"
								to="/admin-panel"
								sx={{color: (theme) => theme.palette.primary.contrastText}}
							>
								Tripsides Admin Panel
							</Button>
							<Tooltip title="Logout">
								<IconButton
									color="inherit"
									onClick={() => {signOut(auth);}}
								>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						</DrawerHeader>
						<List>
							<ListItemButton
								component={Link}
								to="/admin-panel/users"
								selected={location.pathname === '/admin-panel/users'}
							>
								<ListItemText primary={'Benutzer'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/bundles"
								selected={location.pathname === '/admin-panel/bundles'}
							>
								<ListItemText primary={'Bundles'} />
							</ListItemButton>
							<ListItemButton
								component="a"
								href=""
								sx={{marginLeft: '16px'}}
							>
								<ListItemText primary={'Aktivitäten & Tours'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/bars"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/bars'}
							>
								<ListItemText primary={'Bars & Pubs'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/clubs"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/clubs'}
							>
								<ListItemText primary={'Clubs'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/coffeeshops"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/coffeeshops'}
							>
								<ListItemText primary={'Coffeeshops'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/breakfasts"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/breakfasts'}
							>
								<ListItemText primary={'Frühstück & Cafés'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/gelaterie"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/gelaterie'}
							>
								<ListItemText primary={'Gelaterie'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/gentlemens-clubs"
								sx={{marginLeft: '16px'}}
								selected={location.pathname === '/admin-panel/gentlemens-clubs'}
							>
								<ListItemText primary={'Gentlemen’s Clubs'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/restaurants"
								selected={location.pathname === '/admin-panel/restaurants'}
								sx={{marginLeft: '16px'}}
							>
								<ListItemText primary={'Restaurants'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/sights"
								selected={location.pathname === '/admin-panel/sights'}
								sx={{marginLeft: '16px'}}
							>
								<ListItemText primary={'Sehenswürdigkeiten'} />
							</ListItemButton>
							<ListItemButton
								component={Link}
								to="/admin-panel/cities"
								selected={location.pathname === '/admin-panel/cities'}
							>
								<ListItemText primary={'Städte'} />
							</ListItemButton>
						</List>
				</Drawer>
			</>
			}
		</>
	);
}

// Styles
const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(3, 2),
	justifyContent: 'space-between',
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText
}));

export default Menu;
