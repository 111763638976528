import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import BreakfastEditor from './BreakfastEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { Breakfast } from '@models/Breakfast';
import { City } from '@models/City';
import BreakfastsTable from './BreakfastsTable';

interface Props {
	dialog: ConfirmDialog;
}

function Breakfasts(props: Props) {
	const [breakfasts, setBreakfasts] = useState<Breakfast[]>([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [breakfastToEdit, setBreakfastToEdit] = useState<Breakfast | null>(null);
	const [cities, setCities] = useState<City[]>([]);

	useEffect(() => {
		return onSnapshot(collection(db, 'breakfasts'), (snapshot) => {
			const breakfasts: Breakfast[] = []
			snapshot.forEach(document => {
				const documentData = document.data();

				const breakfast: Breakfast = {
					...(documentData as Breakfast),
					id: document.id,
					kitchenType: documentData.kitchenType ? documentData.kitchenType : '',
					kitchenTypeTranslatedGerman: documentData.kitchenTypeTranslatedGerman ? documentData.kitchenTypeTranslatedGerman : ''
				};

				breakfasts.push(breakfast);
			});
			setBreakfasts(breakfasts);
		});
	}, []);

	useEffect(() => {
		return onSnapshot(collection(db, 'cities'), (snapshot) => {
			const cities: City[] = [];
			snapshot.forEach(document => {
				const documentData = document.data();
				const city: City = {
					...(documentData as City),
					id: document.id,
					ref: document.ref
				};

				cities.push(city);
			});
			setCities(cities);
		});
	}, []);

	function handleNewBreakfast(): void {
		setBreakfastToEdit(null);
		setEditorOpen(true);
	}

	function handleEditBreakfast(breakfast: Breakfast): void {
		setBreakfastToEdit(breakfast);
		setEditorOpen(true);
	}

	async function handleDeleteBreakfast(breakfast: Breakfast): Promise<void> {
		const { dialog } = props;

		dialog.confirm({
			title: 'Frühstück/Café Löschen',
			message: `Soll der Eintrag "${breakfast.name}" wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'error',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'primary',
				variant: 'text'
			}
		}).then((): void => {
			if (breakfast.id) {
				deleteDoc(doc(db, 'breakfasts', breakfast.id)).then((): void => {
						return;
					}).catch((error): void => {
						console.log('Failed to delete breakfast: ' + error);
					});
			} else {
				throw new Error('Missing ID');
			}
		}).catch((error: Error): void => {
			if (error) {
				console.log(error);
			}
		});
	}

	function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">{'Frühstück & Cafés'}</Headline>
			<BreakfastsTable 
				breakfasts={breakfasts}
				handleEditBreakfast={handleEditBreakfast}
				handleDeleteBreakfast={handleDeleteBreakfast}
				cities={cities}
			/>
			<BreakfastEditor
				editorOpen={editorOpen}
				closeHandler={handleCloseEditor}
				breakfast={breakfastToEdit}
				cities={cities}
			/>
			<AddButton
				color="primary"
				aria-label="add"
				onClick={() => {handleNewBreakfast();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));

export default withDialog()(Breakfasts);
