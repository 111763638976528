import { useState } from 'react';
import {
	IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { User } from '@models/User';
import {DataGrid, GridColDef, deDE} from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
	dialog: ConfirmDialog;
    users: User[];
    handleEditUser: (user: User) => void;
}

function UsersTable(props: Props) {
    const [pageSize, setPageSize] = useState(20);

    const columns: GridColDef[] = [
        {
            field: 'email',
            headerName: 'E-Mail',
            flex: 1
        },
        {
            field: 'admin',
            headerName: 'Administrator',
            flex: 1,
            renderCell: (params) => (<CheckCircleIcon color="primary" />)
        },
        {
            field: 'buttons',
            headerName: '',
            flex: 0.5,
            align: 'right',
            sortable: false,
            renderCell: (params) => {
                return (
					<IconButton
						onClick={() => props.handleEditUser(params.row)}
						sx={{marginRight: 1}}
                        color='primary'
					>
					    <EditIcon />
					</IconButton>
                );
            }
        }
    ];

	return (
        <DataGridStyled
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.users}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnSelector
            hideFooterSelectedRowCount
            autoHeight
      />
	);
}

// Styles
const DataGridStyled = styled(DataGrid)(({ theme }) => ({
	"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
     },
     marginBottom: theme.spacing(10)
}));

export default withDialog()(UsersTable);
