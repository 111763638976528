import { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography
} from '@mui/material';

import { styled } from '@mui/system';
import { db, auth } from '../../../firebase';
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { User } from '@models/User';


interface Props {
	user: User | null;
	editorOpen: boolean;
	closeHandler: () => void;
	dialog: ConfirmDialog;
}

function UserEditor(props: Props) {
	const [user, setUser] = useState<User>(getDefaultUser());

	useEffect(() => {
		if (props.user) {
			setUser(prevState => ({
				...prevState,
				...props.user
			}));
		} else {
			const defaultUser = getDefaultUser();
			setUser(prevState => ({
				...prevState,
				...defaultUser
			}));
		}
	}, [props.user]);

	function getDefaultUser(): User {
		return {
			email: '',
			password: ''
		};
	}

	function closeEditor(): void {
		if (props.user) {
			setUser(prevState => ({
				...prevState,
				...props.user
			}));
		} else {
			setUser(getDefaultUser());
		}
		props.closeHandler();
	}

	async function saveUser(): Promise<void> {
		if (props.user) {
			await updateUser();
		} else {
			await createUser();
			setUser(getDefaultUser());
		}
		props.closeHandler();
	}

	async function updateUser(): Promise<void> {
		if (user && user.id) {
			try {
				const userRef = doc(db, 'users', user.id);
				await updateDoc(userRef, {
					email: user.email
				});
			} catch (error) {
				console.log('Failed to update user: ' + error);
			}
		}
	}

	async function createUser(): Promise<void> {
		if (user && user.password) {
			try {
				const userCredential = await createUserWithEmailAndPassword(auth, user.email, user.password);
				await setDoc(doc(db, 'users', userCredential.user.uid), {
					email: user.email
				});
				auth.signOut();
			} catch (error) {
			console.log('Failed to create user: ' + error);
			}
		}
	}

	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string): void {
		if (user) {
			const value = event.target.value;
			if (type === 'email') {
				user.email = value;
			} else if (type === 'password') {
				user.password = value;
			}
			setUser(prevState => ({
				...prevState,
				...user
			}));
		}
	};

	function handleSendPasswordResetMail(): void {
		const { dialog } = props;

		dialog.confirm({
			title: 'Passwort Zurücksetzen Mail senden',
			message: `Soll das Passwort Zurücksetzen Mail wirklich an "${user.email}" gesendet werden?`,
			ok: {
				text: 'Senden',
				color: 'primary',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'primary',
				variant: 'text'
			}
		}).then((): void => {
			const auth = getAuth();
			sendPasswordResetEmail(auth, user.email).catch((error) => {
				console.log('Failed to send password reset e-mail: ' + error);
			});
		}).catch((error: Error): void => {
			if (error) {
				console.log(error);
			}
		});
	}

	return (
		<Dialog open={props.editorOpen} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>
				<Typography variant="h4" component="div" display="inline">
					{props.user ? 'Benutzer bearbeiten' : 'Neuer Benutzer'}
				</Typography>
			</DialogTitle>
			<DialogContent>
			<EditorContainer>
				<TextFieldStyled
					required={!props.user}
					InputProps={{
						readOnly: props.user ? true : false
					}}
					id="email"
					label="E-Mail"
					value={user && user.email}
					onChange={(event) => handleChange(event, 'email')}
				/>
				{!props.user &&
					<TextFieldStyled
						required
						id="password"
						label="Passwort"
						type="password"
						value={user && user.password}
						onChange={(event) => handleChange(event, 'password')}
					/>
				}
				{props.user &&
					<PasswordResetButton
						onClick={() => {handleSendPasswordResetMail();}}
						variant="outlined"
					>
					Passwort Zurücksetzen Mail senden
					</PasswordResetButton>
				}
			</EditorContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {closeEditor();}}>
				Abbrechen
				</Button>
				<Button onClick={() => {saveUser();}}>
				Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Styles
const EditorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column'
}));

const TextFieldStyled = styled(TextField)(() => ({
	margin: '10px 0px'
}));

const PasswordResetButton = styled(Button)(() => ({
	width: '35%',
	margin: '10px 0px',
	marginLeft: 'auto',
	marginRight: 'auto'
}));

export default withDialog()(UserEditor);
