import { useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	Select as MuiSelect,
	TextField,
	Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';
import { GentlemensClub } from '@models/GentlemensClub';
import { PriceCategory } from '@models/PriceCategory';
import { Reservation } from '@models/Reservation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Select from '@components/Select';
import { City, CityOption } from '@models/City';


interface Props {
	gentlemensClub: GentlemensClub | null;
	cities: City[];
	editorOpen: boolean;
	closeHandler: () => void;
}

function GentlemensClubEditor(props: Props) {
	const [gentlemensClub, setGentlemensClub] = useState<GentlemensClub>(getDefaultGentlemensClub());
	const [language, setLanguage] = useState('english');
	const [openingHourToAdd, setOpeningHourToAdd] = useState('');
	const [openingHourGermanToAdd, setOpeningHourGermanToAdd] = useState('');
	const [inputError, setInputError] = useState(false);

	useEffect(() => {
		if (props.gentlemensClub) {
			setGentlemensClub(prevState => ({
				...prevState,
				...props.gentlemensClub
			}));
		} else {
			const defaultGentlemensClub = getDefaultGentlemensClub();
			setGentlemensClub(prevState => ({
				...prevState,
				...defaultGentlemensClub
			}));
		}
	}, [props.gentlemensClub]);

	function getDefaultGentlemensClub(): GentlemensClub {
		return {
			name: '',
			street: '',
			houseNumber: '',
			postCode: '',
			city: null,
			country: '',
			countryTranslatedGerman: '',
			phoneNumber: '',
			email: '',
			openingHours: [],
			openingHoursTranslatedGerman: [],
			priceCategory: PriceCategory.MEDIUM,
			reservation: Reservation.YES,
			linkWebsite: '',
			linkInstagram: '',
			linkFacebook: '',
			description: '',
			descriptionTranslatedGerman: ''
		};
	}

	function closeEditor(): void {
		if (props.gentlemensClub) {
			setGentlemensClub(prevState => ({
				...prevState,
				...props.gentlemensClub
			}));
		} else {
			setGentlemensClub(getDefaultGentlemensClub());
		}
		setOpeningHourToAdd('');
		setOpeningHourGermanToAdd('');
		setLanguage('english');
		setInputError(false);
		props.closeHandler();
	}

	async function saveGentlemensClub(): Promise<void> {
		if (gentlemensClub.name === '') {
			setInputError(true);
			return;
		} else {
			setInputError(false);
		}

		if (props.gentlemensClub) {
			await updateGentlemensClub();
		} else {
			await createGentlemensClub();
			setGentlemensClub(getDefaultGentlemensClub());
		}
		setOpeningHourToAdd('');
		setOpeningHourGermanToAdd('');
		setLanguage('english');
		props.closeHandler();
	}

	async function updateGentlemensClub(): Promise<void> {
		if (gentlemensClub && gentlemensClub.id) {
			try {
				const gentlemensClubRef = doc(db, 'gentlemensClubs', gentlemensClub.id);
				delete gentlemensClub.id;
				await updateDoc(gentlemensClubRef, {
					...gentlemensClub
				});
			} catch (error) {
				console.log('Failed to update gentlemensClub: ' + error);
			}
		}
	}

	async function createGentlemensClub(): Promise<void> {
		if (gentlemensClub) {
			try {
				delete gentlemensClub.id;
				await addDoc(collection(db, 'gentlemensClubs'), {
					...gentlemensClub
				});
			} catch (error) {
				console.log('Failed to create gentlemensClub: ' + error);
			}
		}
	}

	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string): void {
		if (gentlemensClub) {
			const value = event.target.value;

			switch (type) {
				case 'name':
				gentlemensClub.name = value;
				break;
				case 'street':
				gentlemensClub.street = value;
				break;
				case 'houseNumber':
				gentlemensClub.houseNumber = value;
				break;
				case 'postCode':
				gentlemensClub.postCode = value;
				break;
				case 'country':
				gentlemensClub.country = value;
				break;
				case 'countryTranslatedGerman':
				gentlemensClub.countryTranslatedGerman = value;
				break;
				case 'phoneNumber':
				gentlemensClub.phoneNumber = value;
				break;
				case 'email':
				gentlemensClub.email = value;
				break;
				case 'linkWebsite':
				gentlemensClub.linkWebsite = value;
				break;
				case 'linkInstagram':
				gentlemensClub.linkInstagram = value;
				break;
				case 'linkFacebook':
				gentlemensClub.linkFacebook = value;
				break;
				case 'description':
				gentlemensClub.description = value;
				break;
				case 'descriptionTranslatedGerman':
				gentlemensClub.descriptionTranslatedGerman = value;
				break;
				default:
				console.log('Value not found!');
			}

			setGentlemensClub(prevState => ({
				...prevState,
				...gentlemensClub
			}));
		}
	};

	function handleOpeningHourToAddChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, language: string): void {
		if (language === 'english') {
			setOpeningHourToAdd(event.target.value);
		} else if (language === 'german') {
			setOpeningHourGermanToAdd(event.target.value);
		}
	};

	function handleReservationChange(event: React.ChangeEvent<HTMLInputElement>): void {
		let selected = Reservation.NO;
		if (event.target.value === Reservation.YES) {
			selected = Reservation.YES
		}

		setGentlemensClub(prevState => ({
			...prevState,
			'reservation': selected
		}));
	};

	function handlePriceCategoryChange(event: React.ChangeEvent<HTMLInputElement>): void {
		let selected = PriceCategory.MEDIUM;
		if (event.target.value === PriceCategory.LOW) {
			selected = PriceCategory.LOW
		} else if (event.target.value === PriceCategory.HIGH) {
			selected = PriceCategory.HIGH
		} else if (event.target.value === PriceCategory.NONE) {
			selected = PriceCategory.NONE
		}

		setGentlemensClub(prevState => ({
			...prevState,
			'priceCategory': selected
		}));
	};

	function handleLanguageChange(event: SelectChangeEvent<string>): void {
		setLanguage(event.target.value as string);
	};

	function handleAddOpeningHour(language: string): void {
		if (language === 'english') {
			const currentOpeningHours = gentlemensClub.openingHours;
			if (openingHourToAdd !== '') {
				currentOpeningHours.push(openingHourToAdd);
				setGentlemensClub(prevState => ({
					...prevState,
					'openingHours': currentOpeningHours
				}));
				setOpeningHourToAdd('');
			}
		} else if (language === 'german') {
			const currentOpeningHoursGerman = gentlemensClub.openingHoursTranslatedGerman;
			if (openingHourGermanToAdd !== '') {
				currentOpeningHoursGerman.push(openingHourGermanToAdd);
				setGentlemensClub(prevState => ({
					...prevState,
					'openingHoursTranslatedGerman': currentOpeningHoursGerman
				}));
				setOpeningHourGermanToAdd('');
			}
		}
	};

	function handleDeleteOpeningHour(index: number): void {
		const currentOpeningHours = gentlemensClub.openingHours;
		currentOpeningHours.splice(index, 1);

		setGentlemensClub(prevState => ({
			...prevState,
			'openingHours': currentOpeningHours
		}));
	};

	function handleDeleteOpeningHourTranslatedGerman(index: number): void {
		const currentOpeningHoursTranslatedGerman = gentlemensClub.openingHoursTranslatedGerman;
		currentOpeningHoursTranslatedGerman.splice(index, 1);

		setGentlemensClub(prevState => ({
			...prevState,
			'openingHoursTranslatedGerman': currentOpeningHoursTranslatedGerman
		}));
	};

	let cityValueOption: CityOption | null = null;
	if (gentlemensClub.city) {
		for (const city of props.cities) {
			if (city.id === gentlemensClub.city.id) {
				cityValueOption = {id: city.id, label: language === 'german' ? city.nameTranslatedGerman : city.name, value: city};
			}
		}
	}

	function handleOnSelectChange(value: any, _: string): void {
		const currentGentlemensClub = gentlemensClub;
		const ref = (value as CityOption).value.ref;
		if (ref) {
			currentGentlemensClub.city = ref;
		}

		setGentlemensClub((prevState) => ({
			...prevState,
			...currentGentlemensClub,
		}));
	}

	function getCityOptions(_: string): CityOption[] {
		const cityOptions: CityOption[] = [];
		for (const city of props.cities) {
			if (city.id) {
				cityOptions.push({
					id: city.id,
					label: language === 'german' ? city.nameTranslatedGerman : city.name,
					value: city,
				});
			}
		}
		return cityOptions;
	}

	return (
		<Dialog open={props.editorOpen} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>
				<Typography variant="h4" component="div" display="inline">
					{props.gentlemensClub ? 'Gentlemen’s Club bearbeiten' : 'Neuer Gentlemen’s Club'}
				</Typography>
				<LanguageSelect>
					<InputLabel>Sprache</InputLabel>
					<MuiSelect
						value={language}
						label="Sprache"
						onChange={handleLanguageChange}
					>
						<MenuItem value={'english'}>Englisch</MenuItem>
						<MenuItem value={'german'}>Deutsch</MenuItem>
					</MuiSelect>
				</LanguageSelect>
			</DialogTitle>
			<DialogContent>
			<EditorContainer>
				<SingleTextField
					required
					id="name"
					label="Name"
					value={gentlemensClub && gentlemensClub.name}
					onChange={(event) => handleChange(event, 'name')}
					error={inputError}
					autoComplete="new-password"
				/>
				<Subtitle variant="h5">
					Adresse
				</Subtitle>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="street"
						label="Straße"
						value={gentlemensClub && gentlemensClub.street}
						onChange={(event) => handleChange(event, 'street')}
						autoComplete="new-password"
					/>
					<TextField
						id="houseNumber"
						label="Hausnummer"
						value={gentlemensClub && gentlemensClub.houseNumber}
						onChange={(event) => handleChange(event, 'houseNumber')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<TextFieldsContainer>
					<MarginRightTextField
						id="postCode"
						label="Postleitzahl"
						value={gentlemensClub && gentlemensClub.postCode}
						onChange={(event) => handleChange(event, 'postCode')}
						autoComplete="new-password"
					/>
					<Select
						required
						value={cityValueOption}
						onChange={(_: React.SyntheticEvent, value: any): void =>
							handleOnSelectChange(value, 'city')
						}
						options={getCityOptions('city')}
						labeltext='Stadt'
						disabled={false}
						loading={false}
						multiple={false}
					/>
				</TextFieldsContainer>
				{language === 'english' &&
					<SingleTextField
						id="country"
						label="Land (Englisch)"
						value={gentlemensClub && gentlemensClub.country}
						onChange={(event) => handleChange(event, 'country')}
						autoComplete="new-password"
					/>
				}
				{language === 'german' &&
					<SingleTextField
						id="countryTranslatedGerman"
						label="Land (Deutsch)"
						value={gentlemensClub && gentlemensClub.countryTranslatedGerman}
						onChange={(event) => handleChange(event, 'countryTranslatedGerman')}
						autoComplete="new-password"
					/>
				}
				<Subtitle variant="h5">
					Kontakt
				</Subtitle>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="phoneNumber"
						label="Telefonnummer"
						value={gentlemensClub && gentlemensClub.phoneNumber}
						onChange={(event) => handleChange(event, 'phoneNumber')}
						autoComplete="new-password"
					/>
					<TextField
						fullWidth
						id="email"
						label="E-Mail"
						value={gentlemensClub && gentlemensClub.email}
						onChange={(event) => handleChange(event, 'email')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="linkInstagram"
						label="Link Instagram"
						value={gentlemensClub && gentlemensClub.linkInstagram}
						onChange={(event) => handleChange(event, 'linkInstagram')}
						autoComplete="new-password"
					/>
					<TextField
						fullWidth
						id="linkFacebook"
						label="Link Facebook"
						value={gentlemensClub && gentlemensClub.linkFacebook}
						onChange={(event) => handleChange(event, 'linkFacebook')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<SingleTextField
					fullWidth
					id="linkWebsite"
					label="Link Website"
					value={gentlemensClub && gentlemensClub.linkWebsite}
					onChange={(event) => handleChange(event, 'linkWebsite')}
					autoComplete="new-password"
				/>
				<Subtitle variant="h5">
					Öffnungszeiten
				</Subtitle>
				{language === 'english' &&
					<>
						<SpaceAroundContainer>
							<SingleTextField
								fullWidth
								id="openingHourToAdd"
								label="Öffnungszeit (Englisch) hinzufügen"
								value={openingHourToAdd}
								onChange={(event) => handleOpeningHourToAddChange(event, 'english')}
								autoComplete="new-password"
							/>
							<IconButton color="primary" onClick={() => {handleAddOpeningHour('english');}} >
								<AddCircleIcon fontSize="large" />
							</IconButton>
						</SpaceAroundContainer>
						<List>
						{gentlemensClub.openingHours.map((value, index) => {
							return (
								<ListItem
									key={value}
									secondaryAction={
										<IconButton edge="end" color="error" onClick={() => {handleDeleteOpeningHour(index);}}>
											<HighlightOffIcon />
										</IconButton>
									}
								>
									<ListItemText id={index.toString()} primary={value} />
								</ListItem>
							);
						})}
						</List>
					</>
				}
				{language === 'german' &&
					<>
						<SpaceAroundContainer>
							<SingleTextField
								fullWidth
								id="openingHourGermanToAdd"
								label="Öffnungszeit (Deutsch) hinzufügen"
								value={openingHourGermanToAdd}
								onChange={(event) => handleOpeningHourToAddChange(event, 'german')}
								autoComplete="new-password"
							/>
							<IconButton color="primary" onClick={() => {handleAddOpeningHour('german');}} >
								<AddCircleIcon fontSize="large" />
							</IconButton>
						</SpaceAroundContainer>
						<List>
						{gentlemensClub.openingHoursTranslatedGerman.map((value, index) => {
							return (
								<ListItem
									key={value}
									secondaryAction={
										<IconButton edge="end" color="error" onClick={() => {handleDeleteOpeningHourTranslatedGerman(index);}}>
											<HighlightOffIcon />
										</IconButton>
									}
								>
									<ListItemText id={index.toString()} primary={value} />
								</ListItem>
							);
						})}
						</List>
					</>
				}
				<Subtitle variant="h5">
					Informationen
				</Subtitle>
				<SpaceAroundContainer>
					<FormControl>
						<FormLabel>Preis-Kategorie</FormLabel>
							<RadioGroup
								value={gentlemensClub.priceCategory}
								onChange={handlePriceCategoryChange}
							>
								<FormControlLabel value={PriceCategory.NONE} control={<Radio />} label="Gratis" />
								<FormControlLabel value={PriceCategory.LOW} control={<Radio />} label="Niedrig" />
								<FormControlLabel value={PriceCategory.MEDIUM} control={<Radio />} label="Mittel" />
								<FormControlLabel value={PriceCategory.HIGH} control={<Radio />} label="Hoch" />
							</RadioGroup>
					</FormControl>
					<FormControl>
						<FormLabel>Reservierung Empfohlen</FormLabel>
							<RadioGroup
								value={gentlemensClub.reservation}
								onChange={handleReservationChange}
							>
								<FormControlLabel value={Reservation.YES} control={<Radio />} label="Ja" />
								<FormControlLabel value={Reservation.NO} control={<Radio />} label="Nein" />
							</RadioGroup>
					</FormControl>
				</SpaceAroundContainer>
				{language === 'english' &&
					<SingleTextField
						id="description"
						label="Beschreibung (Englisch)"
						value={gentlemensClub && gentlemensClub.description}
						onChange={(event) => handleChange(event, 'description')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{language === 'german' &&
					<SingleTextField
						id="descriptionTranslatedGerman"
						label="Beschreibung (Deutsch)"
						value={gentlemensClub && gentlemensClub.descriptionTranslatedGerman}
						onChange={(event) => handleChange(event, 'descriptionTranslatedGerman')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{inputError &&
					<Alert severity="error">Erforderlich Felder müssen ausgefüllt werden!</Alert>
				}
			</EditorContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {closeEditor();}}>
				Abbrechen
				</Button>
				<Button onClick={() => {saveGentlemensClub();}}>
				Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Styles
const EditorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column'
}));

const LanguageSelect = styled(FormControl)(() => ({
	width: '20%',
	float: 'right'
}));

const SingleTextField = styled(TextField)(({ theme }) => ({
	margin: theme.spacing(1.5) + ' 0px'
}));

const TextFieldsContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	margin: theme.spacing(1.5) + ' 0px'
}));

const MarginRightTextField = styled(TextField)(({ theme }) => ({
	marginRight:  theme.spacing(3)
}));

const Subtitle = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2)
}));

const SpaceAroundContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	margin: theme.spacing(1.5) + ' 0px'
}));


export default GentlemensClubEditor;
