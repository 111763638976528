import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
	Autocomplete,
	Checkbox,
	TextField,
	Typography,
} from '@mui/material';
import {ReactNode} from 'react';

interface Props {
	labeltext: string;
	value: any;
	onChange: (event: React.SyntheticEvent, value: any) => void;
	options: any[];
	disabled: boolean;
	loading: boolean;
	multiple: boolean;
    required: boolean;
}

function Select(props: Props) {
	const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
	const checkedIcon = <CheckBoxIcon fontSize='small' />;

	function renderOption(props: any, option: any, {selected}: any): ReactNode {
		return (
			<li {...props}>
				<Checkbox
					icon={icon}
					checkedIcon={checkedIcon}
					style={{marginRight: 8}}
					checked={selected}
				/>
				{option.label}
			</li>
		);
	}

	function renderTags(value: any, _: any): ReactNode {
		const numTags = value.length;
		return (
			<Typography>
				{value.slice(0, 1).map((option: any, _: any) => option.label)}
				{numTags > 1 && ` +${numTags - 1}`}
			</Typography>
		);
	}

	return (
			<Autocomplete
                fullWidth
				disabled={props.disabled}
				disableClearable={props.required}
				loading={props.loading}
				onChange={props.onChange}
				value={props.value}
				options={props.options}
				disablePortal
				renderInput={(params) => <TextField {...params} />}
				renderOption={props.multiple ? renderOption : undefined}
				isOptionEqualToValue={(option, value) =>
					(option.id as string) === (value.id as string)
				}
				noOptionsText='Keine Optionen vorhanden'
				loadingText='Wird geladen...'
				multiple={props.multiple}
				limitTags={props.multiple ? 1 : undefined}
				getOptionLabel={(option) => option.label}
				renderTags={props.multiple ? renderTags : undefined}
			/>
	);
}

export default Select;