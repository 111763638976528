import { useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';
import { City } from '@models/City';


interface Props {
	city: City | null;
	editorOpen: boolean;
	closeHandler: () => void;
}

function CityEditor(props: Props) {
	const [city, setCity] = useState<City>(new City());
	const [inputError, setInputError] = useState(false);

	useEffect(() => {
		if (props.city) {
			setCity(prevState => ({
				...prevState,
				...props.city
			}));
		} else {
			const defaultCity = new City();
			setCity(prevState => ({
				...prevState,
				...defaultCity
			}));
		}
	}, [props.city]);

	function closeEditor(): void {
		if (props.city) {
			setCity(prevState => ({
				...prevState,
				...props.city
			}));
		} else {
			setCity(new City());
		}
		setInputError(false);
		props.closeHandler();
	}

	async function saveCity(): Promise<void> {
		if (city.name === '' || city.nameTranslatedGerman === '') {
			setInputError(true);
			return;
		} else {
			setInputError(false);
		}

		if (props.city) {
			await updateCity();
		} else {
			await createCity();
			setCity(new City());
		}
		props.closeHandler();
	}

	async function updateCity(): Promise<void> {
		if (city && city.id) {
			try {
				const cityRef = doc(db, 'cities', city.id);
				delete city.id;
				delete city.ref;
				await updateDoc(cityRef, {
					...city
				});
			} catch (error) {
				console.log('Failed to update city: ' + error);
			}
		}
	}

	async function createCity(): Promise<void> {
		if (city) {
			try {
				delete city.id;
				delete city.ref;
				await addDoc(collection(db, 'cities'), {
					...city
				});
			} catch (error) {
				console.log('Failed to create city: ' + error);
			}
		}
	}

	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string): void {
		if (city) {
			const value = event.target.value;

			switch (type) {
				case 'name':
				city.name = value;
				break;
                case 'nameTranslatedGerman':
				city.nameTranslatedGerman = value;
				break;
				default:
				console.log('Value not found!');
			}

			setCity(prevState => ({
				...prevState,
				...city
			}));
		}
	};

	return (
		<Dialog open={props.editorOpen} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>
				<Typography variant="h4" component="div" display="inline">
					{props.city ? 'Stadt bearbeiten' : 'Neue Stadt'}
				</Typography>
			</DialogTitle>
			<DialogContent>
			<EditorContainer>
				<SingleTextField
					required
					id="name"
					label="Name (Englisch)"
					value={city && city.name}
					onChange={(event) => handleChange(event, 'name')}
					error={inputError}
					autoComplete="new-password"
				/>
				<SingleTextField
					required
					id="nameTranslatedGerman"
					label="Name (Deutsch)"
					value={city && city.nameTranslatedGerman}
					onChange={(event) => handleChange(event, 'nameTranslatedGerman')}
					error={inputError}
					autoComplete="new-password"
				/>
				{inputError &&
					<Alert severity="error">Erforderlich Felder müssen ausgefüllt werden!</Alert>
				}
			</EditorContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {closeEditor();}}>
				Abbrechen
				</Button>
				<Button onClick={() => {saveCity();}}>
				Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Styles
const EditorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column'
}));

const SingleTextField = styled(TextField)(({ theme }) => ({
	margin: theme.spacing(1.5) + ' 0px'
}));

export default CityEditor;