import { Navigate, Outlet } from 'react-router-dom';

interface Props {
	isLogged: null | boolean;
}

function PrivateRoute(props: Props) {
	return props.isLogged  ? <Outlet /> : props.isLogged === false ? <Navigate to="/admin-panel/login" /> : <></>;
}

export default PrivateRoute;
