import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import GentlemensClubEditor from './GentlemensClubEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { GentlemensClub } from '@models/GentlemensClub';
import { City } from '@models/City';
import GentlemensClubsTable from './GentlemensClubsTable';

interface Props { 
	dialog: ConfirmDialog;
}

function GentlemensClubs(props: Props) {
	const [gentlemensClubs, setGentlemensClubs] = useState<GentlemensClub[]>([]);
	const [cities, setCities] = useState<City[]>([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [gentlemensClubToEdit, setGentlemensClubToEdit] = useState<GentlemensClub | null>(null);

	useEffect(() => {
		return onSnapshot(collection(db, 'gentlemensClubs'), (snapshot) => {
			const gentlemensClubs: GentlemensClub[] = []
			snapshot.forEach(document => {
				const documentData = document.data();

				const gentlemensClub: GentlemensClub = {
					...(documentData as GentlemensClub),
					id: document.id
				};

				gentlemensClubs.push(gentlemensClub);
			});
			setGentlemensClubs(gentlemensClubs);
		});
	}, []);

	useEffect(() => {
		return onSnapshot(collection(db, 'cities'), (snapshot) => {
			const cities: City[] = [];
			snapshot.forEach(document => {
				const documentData = document.data();
				const city: City = {
					...(documentData as City),
					id: document.id,
					ref: document.ref
				};

				cities.push(city);
			});
			setCities(cities);
		});
	}, []);

	function handleNewGentlemensClub(): void {
		setGentlemensClubToEdit(null);
		setEditorOpen(true);
	}

	function handleEditGentlemensClub(gentlemensClub: GentlemensClub): void {
		setGentlemensClubToEdit(gentlemensClub);
		setEditorOpen(true);
	}

	async function handleDeleteGentlemensClub(gentlemensClub: GentlemensClub): Promise<void> {
		const { dialog } = props;

		dialog.confirm({
			title: 'Gentlemen’s Club Löschen',
			message: `Soll der Gentlemen’s Club "${gentlemensClub.name}" wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'error',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'primary',
				variant: 'text'
			}
		}).then((): void => {
			if (gentlemensClub.id) {
				deleteDoc(doc(db, 'gentlemensClubs', gentlemensClub.id)).then((): void => {
						return;
					}).catch((error): void => {
						console.log('Failed to delete gentlemensClub: ' + error);
					});
			} else {
				throw new Error('Missing ID');
			}
		}).catch((error: Error): void => {
			if (error) {
				console.log(error);
			}
		});
	}

	function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">Gentlemen’s Clubs</Headline>
			<GentlemensClubsTable 
				gentlemensClubs={gentlemensClubs}
				cities={cities}
				handleEditGentlemensClub={handleEditGentlemensClub}
				handleDeleteGentlemensClub={handleDeleteGentlemensClub}
			/>
			<GentlemensClubEditor
				editorOpen={editorOpen}
				cities={cities}
				closeHandler={handleCloseEditor}
				gentlemensClub={gentlemensClubToEdit}
			/>
			<AddButton
				color="primary"
				aria-label="add"
				onClick={() => {handleNewGentlemensClub();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));

export default withDialog()(GentlemensClubs);
