import { useState } from 'react';
import {
	IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { City } from '@models/City';
import {DataGrid, GridColDef, deDE, GridValueGetterParams} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
	dialog: ConfirmDialog;
    cities: City[];
    handleEditCity: (city: City) => void;
    handleDeleteCity: (city: City) => void;
}

function CitiesTable(props: Props) {
    const [pageSize, setPageSize] = useState(20);

    const columns: GridColDef[] = [
        {
            field: 'nameGerman',
            headerName: 'Name (Deutsch)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.nameTranslatedGerman
        },
        {
            field: 'nameEnglish',
            headerName: 'Name (Englisch)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.name
        },
        {
            field: 'buttons',
            headerName: '',
            flex: 0.5,
            align: 'right',
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
						<IconButton
						    onClick={() => props.handleEditCity(params.row)}
						    sx={{marginRight: 1}}
                            color='primary'
					    >
					        <EditIcon />
					    </IconButton>
						<IconButton
						    onClick={() => props.handleDeleteCity(params.row)}
						    sx={{marginRight: 1}}
                            color='error'
					    >
					        <HighlightOffIcon />
					    </IconButton>
					</div>
                );
            }
        }
    ];

    function getTableRows(): any[] {
        const rows: any[] = [];
        for (const city of props.cities) {
            rows.push({
                id: city.id,
                nameGerman: city.nameTranslatedGerman,
                nameEnglish: city.name,
                object: city
            });
        }
        return rows;
    }

	return (
        <DataGridStyled
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.cities}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnSelector
            hideFooterSelectedRowCount
            autoHeight
      />
	);
}

// Styles
const DataGridStyled = styled(DataGrid)(({ theme }) => ({
	"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
     },
     marginBottom: theme.spacing(10)
}));

export default withDialog()(CitiesTable);
