import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import BarEditor from './BarEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { Bar } from '@models/Bar';
import { City } from '@models/City';
import BarsTable from './BarsTable';

interface Props {
	dialog: ConfirmDialog;
}

function Bars(props: Props) {
	const [bars, setBars] = useState<Bar[]>([]);
	const [cities, setCities] = useState<City[]>([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [barToEdit, setBarToEdit] = useState<Bar | null>(null);

	useEffect(() => {
		return onSnapshot(collection(db, 'bars'), (snapshot) => {
			const bars: Bar[] = []
			snapshot.forEach(document => {
				const documentData = document.data();

				const bar: Bar = {
					...(documentData as Bar),
					id: document.id
				};

				bars.push(bar);
			});
			setBars(bars);
		});
	}, []);

	useEffect(() => {
		return onSnapshot(collection(db, 'cities'), (snapshot) => {
			const cities: City[] = [];
			snapshot.forEach(document => {
				const documentData = document.data();
				const city: City = {
					...(documentData as City),
					id: document.id,
					ref: document.ref
				};

				cities.push(city);
			});
			setCities(cities);
		});
	}, []);

	function handleNewBar(): void {
		setBarToEdit(null);
		setEditorOpen(true);
	}

	function handleEditBar(bar: Bar): void {
		setBarToEdit(bar);
		setEditorOpen(true);
	}

	async function handleDeleteBar(bar: Bar): Promise<void> {
		const { dialog } = props;

		dialog.confirm({
			title: 'Bar/Pub Löschen',
			message: `Soll der Eintrag "${bar.name}" wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'error',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'primary',
				variant: 'text'
			}
		}).then((): void => {
			if (bar.id) {
				deleteDoc(doc(db, 'bars', bar.id)).then((): void => {
						return;
					}).catch((error): void => {
						console.log('Failed to delete bar: ' + error);
					});
			} else {
				throw new Error('Missing ID');
			}
		}).catch((error: Error): void => {
			if (error) {
				console.log(error);
			}
		});
	}

	function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">{'Bars & Pubs'}</Headline>
			<BarsTable 
				bars={bars}
				cities={cities}
				handleEditBar={handleEditBar}
				handleDeleteBar={handleDeleteBar}
			/>
			<BarEditor
				editorOpen={editorOpen}
				closeHandler={handleCloseEditor}
				bar={barToEdit}
				cities={cities}
			/>
			<AddButton
				color="primary"
				aria-label="add"
				onClick={() => {handleNewBar();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));

export default withDialog()(Bars);
