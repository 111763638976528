import { useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	Select as MuiSelect,
	TextField,
	Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';
import { Breakfast } from '@models/Breakfast';
import { PriceCategory } from '@models/PriceCategory';
import { Reservation } from '@models/Reservation';
import { VeganVegetarian } from '@models/VeganVegetarian';
import { City, CityOption } from '@models/City';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Select from '@components/Select';


interface Props {
	breakfast: Breakfast | null;
	editorOpen: boolean;
	closeHandler: () => void;
	cities: City[];
}

function BreakfastEditor(props: Props) {
	const [breakfast, setBreakfast] = useState<Breakfast>(getDefaultBreakfast());
	const [language, setLanguage] = useState('english');
	const [openingHourToAdd, setOpeningHourToAdd] = useState('');
	const [openingHourGermanToAdd, setOpeningHourGermanToAdd] = useState('');
	const [inputError, setInputError] = useState(false);

	useEffect(() => {
		if (props.breakfast) {
			setBreakfast(prevState => ({
				...prevState,
				...props.breakfast
			}));
		} else {
			const defaultBreakfast = getDefaultBreakfast();
			setBreakfast(prevState => ({
				...prevState,
				...defaultBreakfast
			}));
		}
	}, [props.breakfast]);

	function getDefaultBreakfast(): Breakfast {
		return {
			name: '',
			street: '',
			houseNumber: '',
			postCode: '',
			city: null,
			country: '',
			countryTranslatedGerman: '',
			phoneNumber: '',
			email: '',
			openingHours: [],
			openingHoursTranslatedGerman: [],
			priceCategory: PriceCategory.MEDIUM,
			reservation: Reservation.YES,
			veganVegetarian: VeganVegetarian.YES,
			linkMenu: '',
			linkWebsite: '',
			linkInstagram: '',
			linkFacebook: '',
			kitchenType: '',
			kitchenTypeTranslatedGerman: '',
			description: '',
			descriptionTranslatedGerman: ''
		};
	}

	function closeEditor(): void {
		if (props.breakfast) {
			setBreakfast(prevState => ({
				...prevState,
				...props.breakfast
			}));
		} else {
			setBreakfast(getDefaultBreakfast());
		}
		setOpeningHourToAdd('');
		setOpeningHourGermanToAdd('');
		setLanguage('english');
		setInputError(false);
		props.closeHandler();
	}

	async function saveBreakfast(): Promise<void> {
		if (breakfast.name === '') {
			setInputError(true);
			return;
		} else {
			setInputError(false);
		}

		if (props.breakfast) {
			await updateBreakfast();
		} else {
			await createBreakfast();
			setBreakfast(getDefaultBreakfast());
		}
		setOpeningHourToAdd('');
		setOpeningHourGermanToAdd('');
		setLanguage('english');
		props.closeHandler();
	}

	async function updateBreakfast(): Promise<void> {
		if (breakfast && breakfast.id) {
			try {
				const breakfastRef = doc(db, 'breakfasts', breakfast.id);
				delete breakfast.id;
				await updateDoc(breakfastRef, {
					...breakfast
				});
			} catch (error) {
				console.log('Failed to update breakfast: ' + error);
			}
		}
	}

	async function createBreakfast(): Promise<void> {
		if (breakfast) {
			try {
				delete breakfast.id;
				await addDoc(collection(db, 'breakfasts'), {
					...breakfast
				});
			} catch (error) {
				console.log('Failed to create breakfast: ' + error);
			}
		}
	}

	function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string): void {
		if (breakfast) {
			const value = event.target.value;

			switch (type) {
				case 'name':
				breakfast.name = value;
				break;
				case 'street':
				breakfast.street = value;
				break;
				case 'houseNumber':
				breakfast.houseNumber = value;
				break;
				case 'postCode':
				breakfast.postCode = value;
				break;
				case 'country':
				breakfast.country = value;
				break;
				case 'countryTranslatedGerman':
				breakfast.countryTranslatedGerman = value;
				break;
				case 'phoneNumber':
				breakfast.phoneNumber = value;
				break;
				case 'email':
				breakfast.email = value;
				break;
				case 'linkMenu':
				breakfast.linkMenu = value;
				break;
				case 'linkWebsite':
				breakfast.linkWebsite = value;
				break;
				case 'linkInstagram':
				breakfast.linkInstagram = value;
				break;
				case 'linkFacebook':
				breakfast.linkFacebook = value;
				break;
				case 'kitchenType':
				breakfast.kitchenType = value;
				break;
				case 'kitchenTypeTranslatedGerman':
				breakfast.kitchenTypeTranslatedGerman = value;
				break;
				case 'description':
				breakfast.description = value;
				break;
				case 'descriptionTranslatedGerman':
				breakfast.descriptionTranslatedGerman = value;
				break;
				default:
				console.log('Value not found!');
			}

			setBreakfast(prevState => ({
				...prevState,
				...breakfast
			}));
		}
	};

	function handleOpeningHourToAddChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, language: string): void {
		if (language === 'english') {
			setOpeningHourToAdd(event.target.value);
		} else if (language === 'german') {
			setOpeningHourGermanToAdd(event.target.value);
		}
	};

	function handleReservationChange(event: React.ChangeEvent<HTMLInputElement>): void {
		let selected = Reservation.NO;
		if (event.target.value === Reservation.YES) {
			selected = Reservation.YES
		}

		setBreakfast(prevState => ({
			...prevState,
			'reservation': selected
		}));
	};

	function handleVeganVegetarianChange(event: React.ChangeEvent<HTMLInputElement>): void {
		let selected = VeganVegetarian.NO;
		if (event.target.value === VeganVegetarian.YES) {
			selected = VeganVegetarian.YES
		}

		setBreakfast(prevState => ({
			...prevState,
			'veganVegetarian': selected
		}));
	};

	function handlePriceCategoryChange(event: React.ChangeEvent<HTMLInputElement>): void {
		let selected = PriceCategory.MEDIUM;
		if (event.target.value === PriceCategory.LOW) {
			selected = PriceCategory.LOW
		} else if (event.target.value === PriceCategory.HIGH) {
			selected = PriceCategory.HIGH
		} else if (event.target.value === PriceCategory.NONE) {
			selected = PriceCategory.NONE
		}

		setBreakfast(prevState => ({
			...prevState,
			'priceCategory': selected
		}));
	};

	function handleLanguageChange(event: SelectChangeEvent<string>): void {
		setLanguage(event.target.value as string);
	};

	function handleAddOpeningHour(language: string): void {
		if (language === 'english') {
			const currentOpeningHours = breakfast.openingHours;
			if (openingHourToAdd !== '') {
				currentOpeningHours.push(openingHourToAdd);
				setBreakfast(prevState => ({
					...prevState,
					'openingHours': currentOpeningHours
				}));
				setOpeningHourToAdd('');
			}
		} else if (language === 'german') {
			const currentOpeningHoursGerman = breakfast.openingHoursTranslatedGerman;
			if (openingHourGermanToAdd !== '') {
				currentOpeningHoursGerman.push(openingHourGermanToAdd);
				setBreakfast(prevState => ({
					...prevState,
					'openingHoursTranslatedGerman': currentOpeningHoursGerman
				}));
				setOpeningHourGermanToAdd('');
			}
		}
	};

	function handleDeleteOpeningHour(index: number): void {
		const currentOpeningHours = breakfast.openingHours;
		currentOpeningHours.splice(index, 1);

		setBreakfast(prevState => ({
			...prevState,
			'openingHours': currentOpeningHours
		}));
	};

	function handleDeleteOpeningHourTranslatedGerman(index: number): void {
		const currentOpeningHoursTranslatedGerman = breakfast.openingHoursTranslatedGerman;
		currentOpeningHoursTranslatedGerman.splice(index, 1);

		setBreakfast(prevState => ({
			...prevState,
			'openingHoursTranslatedGerman': currentOpeningHoursTranslatedGerman
		}));
	};

	let cityValueOption: CityOption | null = null;
	if (breakfast.city) {
		for (const city of props.cities) {
			if (city.id === breakfast.city.id) {
				cityValueOption = {id: city.id, label: city.nameTranslatedGerman, value: city};
			}
		}
	}

	function handleOnSelectChange(value: any, _: string): void {
		const currentBreakfast = breakfast;
		const ref = (value as CityOption).value.ref;
		if (ref) {
			currentBreakfast.city = ref;
		}

		setBreakfast((prevState) => ({
			...prevState,
			...currentBreakfast,
		}));
	}

	function getCityOptions(_: string): CityOption[] {
		const cityOptions: CityOption[] = [];
		for (const city of props.cities) {
			if (city.id) {
				cityOptions.push({
					id: city.id,
					label: city.nameTranslatedGerman,
					value: city,
				});
			}
		}
		return cityOptions;
	}

	return (
		<Dialog open={props.editorOpen} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>
				<Typography variant="h4" component="div" display="inline">
					{props.breakfast ? 'Frühstück/Café bearbeiten' : 'Neues Frühstück/Café'}
				</Typography>
				<LanguageSelect>
					<InputLabel>Sprache</InputLabel>
					<MuiSelect
						value={language}
						label="Sprache"
						onChange={handleLanguageChange}
					>
						<MenuItem value={'english'}>Englisch</MenuItem>
						<MenuItem value={'german'}>Deutsch</MenuItem>
					</MuiSelect>
				</LanguageSelect>
			</DialogTitle>
			<DialogContent>
			<EditorContainer>
				<SingleTextField
					required
					id="name"
					label="Name"
					value={breakfast && breakfast.name}
					onChange={(event) => handleChange(event, 'name')}
					error={inputError}
					autoComplete="new-password"
				/>
				<Subtitle variant="h5">
					Adresse
				</Subtitle>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="street"
						label="Straße"
						value={breakfast && breakfast.street}
						onChange={(event) => handleChange(event, 'street')}
						autoComplete="new-password"
					/>
					<TextField
						id="houseNumber"
						label="Hausnummer"
						value={breakfast && breakfast.houseNumber}
						onChange={(event) => handleChange(event, 'houseNumber')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<TextFieldsContainer>
					<MarginRightTextField
						id="postCode"
						label="Postleitzahl"
						value={breakfast && breakfast.postCode}
						onChange={(event) => handleChange(event, 'postCode')}
						autoComplete="new-password"
					/>
					<Select
						required
						value={cityValueOption}
						onChange={(_: React.SyntheticEvent, value: any): void =>
							handleOnSelectChange(value, 'city')
						}
						options={getCityOptions('city')}
						labeltext='Stadt'
						disabled={false}
						loading={false}
						multiple={false}
					/>
				</TextFieldsContainer>
				{language === 'english' &&
					<SingleTextField
						id="country"
						label="Land (Englisch)"
						value={breakfast && breakfast.country}
						onChange={(event) => handleChange(event, 'country')}
						autoComplete="new-password"
					/>
				}
				{language === 'german' &&
					<SingleTextField
						id="countryTranslatedGerman"
						label="Land (Deutsch)"
						value={breakfast && breakfast.countryTranslatedGerman}
						onChange={(event) => handleChange(event, 'countryTranslatedGerman')}
						autoComplete="new-password"
					/>
				}
				<Subtitle variant="h5">
					Kontakt
				</Subtitle>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="phoneNumber"
						label="Telefonnummer"
						value={breakfast && breakfast.phoneNumber}
						onChange={(event) => handleChange(event, 'phoneNumber')}
						autoComplete="new-password"
					/>
					<TextField
						fullWidth
						id="email"
						label="E-Mail"
						value={breakfast && breakfast.email}
						onChange={(event) => handleChange(event, 'email')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="linkInstagram"
						label="Link Instagram"
						value={breakfast && breakfast.linkInstagram}
						onChange={(event) => handleChange(event, 'linkInstagram')}
						autoComplete="new-password"
					/>
					<TextField
						fullWidth
						id="linkFacebook"
						label="Link Facebook"
						value={breakfast && breakfast.linkFacebook}
						onChange={(event) => handleChange(event, 'linkFacebook')}
						autoComplete="new-password"
					/>
				</TextFieldsContainer>
				<SingleTextField
					fullWidth
					id="linkWebsite"
					label="Link Website"
					value={breakfast && breakfast.linkWebsite}
					onChange={(event) => handleChange(event, 'linkWebsite')}
					autoComplete="new-password"
				/>
				<Subtitle variant="h5">
					Öffnungszeiten
				</Subtitle>
				{language === 'english' &&
					<>
						<SpaceAroundContainer>
							<SingleTextField
								fullWidth
								id="openingHourToAdd"
								label="Öffnungszeit (Englisch) hinzufügen"
								value={openingHourToAdd}
								onChange={(event) => handleOpeningHourToAddChange(event, 'english')}
								autoComplete="new-password"
							/>
							<IconButton color="primary" onClick={() => {handleAddOpeningHour('english');}} >
								<AddCircleIcon fontSize="large" />
							</IconButton>
						</SpaceAroundContainer>
						<List>
						{breakfast.openingHours.map((value, index) => {
							return (
								<ListItem
									key={value}
									secondaryAction={
										<IconButton edge="end" color="error" onClick={() => {handleDeleteOpeningHour(index);}}>
											<HighlightOffIcon />
										</IconButton>
									}
								>
									<ListItemText id={index.toString()} primary={value} />
								</ListItem>
							);
						})}
						</List>
					</>
				}
				{language === 'german' &&
					<>
						<SpaceAroundContainer>
							<SingleTextField
								fullWidth
								id="openingHourGermanToAdd"
								label="Öffnungszeit (Deutsch) hinzufügen"
								value={openingHourGermanToAdd}
								onChange={(event) => handleOpeningHourToAddChange(event, 'german')}
								autoComplete="new-password"
							/>
							<IconButton color="primary" onClick={() => {handleAddOpeningHour('german');}} >
								<AddCircleIcon fontSize="large" />
							</IconButton>
						</SpaceAroundContainer>
						<List>
						{breakfast.openingHoursTranslatedGerman.map((value, index) => {
							return (
								<ListItem
									key={value}
									secondaryAction={
										<IconButton edge="end" color="error" onClick={() => {handleDeleteOpeningHourTranslatedGerman(index);}}>
											<HighlightOffIcon />
										</IconButton>
									}
								>
									<ListItemText id={index.toString()} primary={value} />
								</ListItem>
							);
						})}
						</List>
					</>
				}
				<Subtitle variant="h5">
					Informationen
				</Subtitle>
				<SpaceAroundContainer>
					<FormControl>
						<FormLabel>Preis-Kategorie</FormLabel>
							<RadioGroup
								value={breakfast.priceCategory}
								onChange={handlePriceCategoryChange}
							>
								<FormControlLabel value={PriceCategory.LOW} control={<Radio />} label="Niedrig" />
								<FormControlLabel value={PriceCategory.MEDIUM} control={<Radio />} label="Mittel" />
								<FormControlLabel value={PriceCategory.HIGH} control={<Radio />} label="Hoch" />
							</RadioGroup>
					</FormControl>
					<FormControl>
						<FormLabel>Reservierung Empfohlen</FormLabel>
							<RadioGroup
								value={breakfast.reservation}
								onChange={handleReservationChange}
							>
								<FormControlLabel value={Reservation.YES} control={<Radio />} label="Ja" />
								<FormControlLabel value={Reservation.NO} control={<Radio />} label="Nein" />
							</RadioGroup>
					</FormControl>
					<FormControl>
						<FormLabel>Vegan / Vegetarisch</FormLabel>
							<RadioGroup
								value={breakfast.veganVegetarian}
								onChange={handleVeganVegetarianChange}
							>
								<FormControlLabel value={VeganVegetarian.YES} control={<Radio />} label="Ja" />
								<FormControlLabel value={VeganVegetarian.NO} control={<Radio />} label="Nein" />
							</RadioGroup>
					</FormControl>
				</SpaceAroundContainer>
				<TextFieldsContainer>
					<MarginRightTextField
						fullWidth
						id="linkMenu"
						label="Link Speisekarte"
						value={breakfast && breakfast.linkMenu}
						onChange={(event) => handleChange(event, 'linkMenu')}
						autoComplete="new-password"
					/>
					{language === 'english' &&
						<TextField
							fullWidth
							id="kitchenType"
							label="Küchenart (Englisch)"
							value={breakfast && breakfast.kitchenType}
							onChange={(event) => handleChange(event, 'kitchenType')}
							autoComplete="new-password"
						/>
					}
					{language === 'german' &&
						<TextField
							fullWidth
							id="kitchenTypeTranslatedGerman"
							label="Küchenart (Deutsch)"
							value={breakfast && breakfast.kitchenTypeTranslatedGerman}
							onChange={(event) => handleChange(event, 'kitchenTypeTranslatedGerman')}
							autoComplete="new-password"
						/>
					}
				</TextFieldsContainer>
				{language === 'english' &&
					<SingleTextField
						id="description"
						label="Beschreibung (Englisch)"
						value={breakfast && breakfast.description}
						onChange={(event) => handleChange(event, 'description')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{language === 'german' &&
					<SingleTextField
						id="descriptionTranslatedGerman"
						label="Beschreibung (Deutsch)"
						value={breakfast && breakfast.descriptionTranslatedGerman}
						onChange={(event) => handleChange(event, 'descriptionTranslatedGerman')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{inputError &&
					<Alert severity="error">Erforderlich Felder müssen ausgefüllt werden!</Alert>
				}
			</EditorContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {closeEditor();}}>
				Abbrechen
				</Button>
				<Button onClick={() => {saveBreakfast();}}>
				Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Styles
const EditorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column'
}));

const LanguageSelect = styled(FormControl)(() => ({
	width: '20%',
	float: 'right'
}));

const SingleTextField = styled(TextField)(({ theme }) => ({
	margin: theme.spacing(1.5) + ' 0px'
}));

const TextFieldsContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	margin: theme.spacing(1.5) + ' 0px'
}));

const MarginRightTextField = styled(TextField)(({ theme }) => ({
	marginRight:  theme.spacing(3)
}));

const Subtitle = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2)
}));

const SpaceAroundContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	margin: theme.spacing(1.5) + ' 0px'
}));


export default BreakfastEditor;
