import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { useState, useEffect } from 'react';
import Menu from './Menu';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import Home from '../Website/Home';
import Login from '@screens/Login/Login';
import Dashboard from '@screens/Dashboard/Dashboard';
import Users from '@screens/Users/Users';
import Restaurants from '@screens/Restaurants/Restaurants';
import Sights from '@screens/Sights/Sights';
import Coffeeshops from '@screens/Coffeeshops/Coffeeshops';
import Breakfasts from '@screens/Breakfasts/Breakfasts';
import Bars from '@screens/Bars/Bars';
import Clubs from '@screens/Clubs/Clubs';
import Gelaterie from '@screens/Gelaterie/Gelaterie';
import Bundles from '@screens/Bundles/Bundles';
import Cities from '@screens/Cities/Cities';
import GentlemensClubs from '@screens/GentlemensClubs/GentlemensClubs';

function App() {

    const [isLogged, setIsLogged] = useState<boolean | null>(null);
    const currentAuth = auth;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
        	if (user) {
                setIsLogged(true);
        	} else {
                setIsLogged(false);
        	}
    	});
        return unsubscribe;
    }, [currentAuth]);

    return (
        <>
            <BrowserRouter>
            {isLogged && <Menu />}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route element={<PrivateRoute isLogged={isLogged} />}>
                        <Route path="/admin-panel" element={<Dashboard />} />
                        <Route path="/admin-panel/users" element={<Users />} />
                        <Route path="/admin-panel/bundles" element={<Bundles />} />
                        <Route path="/admin-panel/cities" element={<Cities />} />
                        <Route path="/admin-panel/bars" element={<Bars />} />
                        <Route path="/admin-panel/clubs" element={<Clubs />} />
                        <Route path="/admin-panel/coffeeshops" element={<Coffeeshops />} />
                        <Route path="/admin-panel/breakfasts" element={<Breakfasts />} />
                        <Route path="/admin-panel/gelaterie" element={<Gelaterie />} />
                        <Route path="/admin-panel/gentlemens-clubs" element={<GentlemensClubs />} />
                        <Route path="/admin-panel/restaurants" element={<Restaurants />} />
                        <Route path="/admin-panel/sights" element={<Sights />} />
                        <Route path="/admin-panel/*" element={<Navigate to={'/admin-panel'} />} />
                    </Route>
                    <Route path="/admin-panel/login" element={isLogged ? <Navigate to='/admin-panel' /> : <Login />} />
                    <Route path="*" element={<Navigate to='/' />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
