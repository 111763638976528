import { useState, useEffect } from 'react';
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
    InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
    Stack,
    Switch,
	TextField,
	Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';
import { Bundle } from '@models/Bundle';
import { PriceCategory } from '@models/PriceCategory';
import { Reservation } from '@models/Reservation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
	bundle: Bundle | null;
	editorOpen: boolean;
	closeHandler: () => void;
}

function BundleEditor(props: Props) {
	const [bundle, setBundle] = useState<Bundle>(new Bundle());
	const [language, setLanguage] = useState('english');
	const [inputError, setInputError] = useState(false);

	useEffect(() => {
		if (props.bundle) {
			setBundle(prevState => ({
				...prevState,
				...props.bundle
			}));
		} else {
			const defaultBundle = new Bundle();
			setBundle(prevState => ({
				...prevState,
				...defaultBundle
			}));
		}
	}, [props.bundle]);

	function closeEditor(): void {
		if (props.bundle) {
			setBundle(prevState => ({
				...prevState,
				...props.bundle
			}));
		} else {
			setBundle(new Bundle());
		}
		setLanguage('english');
		setInputError(false);
		props.closeHandler();
	}

	async function saveBundle(): Promise<void> {
		if (bundle.name === '' || bundle.price === 0) {
			setInputError(true);
			return;
		} else {
			setInputError(false);
		}

		if (props.bundle) {
			await updateBundle();
		} else {
			await createBundle();
			setBundle(new Bundle());
		}

		setLanguage('english');
		props.closeHandler();
	}

	async function updateBundle(): Promise<void> {
		if (bundle && bundle.id) {
			try {
				const bundleRef = doc(db, 'bundles', bundle.id);
				delete bundle.id;
				await updateDoc(bundleRef, {
					...bundle
				});
			} catch (error) {
				console.log('Failed to update bundle: ' + error);
			}
		}
	}

	async function createBundle(): Promise<void> {
		if (bundle) {
			try {
				delete bundle.id;
				await addDoc(collection(db, 'bundles'), {
					...bundle
				});
			} catch (error) {
				console.log('Failed to create bundle: ' + error);
			}
		}
	}

	function handleLanguageChange(event: SelectChangeEvent<string>): void {
		setLanguage(event.target.value as string);
	};

	function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
		if (bundle) {
			bundle.active = checked;
			setBundle(prevState => ({
				...prevState,
				...bundle
			}));
		}
	};

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string): void {
		if (bundle) {
			const value = event.target.value;

			switch (type) {
				case 'name':
                    bundle.name = value;
				    break;
				case 'description':
                    bundle.description = value;
				    break;
				case 'descriptionTranslatedGerman':
                    bundle.descriptionTranslatedGerman = value;
				    break;
				default:
				    console.log('Value not found!');
			}

			setBundle(prevState => ({
				...prevState,
				...bundle
			}));
		}
	};

	return (
		<Dialog open={props.editorOpen} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>
				<Typography variant="h4" component="div" display="inline">
					{props.bundle ? 'Bundle bearbeiten' : 'Neues Bundle'}
				</Typography>
				<LanguageSelect>
					<InputLabel>Sprache</InputLabel>
					<Select
						value={language}
						label="Sprache"
						onChange={handleLanguageChange}
					>
						<MenuItem value={'english'}>Englisch</MenuItem>
						<MenuItem value={'german'}>Deutsch</MenuItem>
					</Select>
				</LanguageSelect>
			</DialogTitle>
			<DialogContent>
			<EditorContainer>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Inaktiv</Typography>
                    <Switch checked={bundle.active} onChange={handleSwitchChange} />
                    <Typography>Aktiv</Typography>
                </Stack>
				<SingleTextField
					required
					id="name"
					label="Name"
					value={bundle && bundle.name}
					onChange={(event) => {console.log(event);}}
					error={inputError}
					autoComplete="new-password"
				/>
                <SingleTextField
                    required
                    id="price"
                    label="Preis"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />
                {language === 'english' &&
					<SingleTextField
						id="description"
						label="Beschreibung (Englisch)"
						value={bundle && bundle.description}
						onChange={(event) => handleChange(event, 'description')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{language === 'german' &&
					<SingleTextField
						id="descriptionTranslatedGerman"
						label="Beschreibung (Deutsch)"
						value={bundle && bundle.descriptionTranslatedGerman}
						onChange={(event) => handleChange(event, 'descriptionTranslatedGerman')}
						multiline={true}
						rows={4}
						autoComplete="new-password"
					/>
				}
				{inputError &&
					<Alert severity="error">Erforderlich Felder müssen ausgefüllt werden!</Alert>
				}
			</EditorContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {closeEditor();}}>
				Abbrechen
				</Button>
				<Button onClick={() => {saveBundle();}}>
				Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

// Styles
const EditorContainer = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column'
}));

const LanguageSelect = styled(FormControl)(() => ({
	width: '20%',
	float: 'right'
}));

const SingleTextField = styled(TextField)(({ theme }) => ({
	margin: theme.spacing(1.5) + ' 0px'
}));

const TextFieldsContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	margin: theme.spacing(1.5) + ' 0px'
}));

const MarginRightTextField = styled(TextField)(({ theme }) => ({
	marginRight:  theme.spacing(3)
}));

const Subtitle = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2)
}));

const SpaceAroundContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	margin: theme.spacing(1.5) + ' 0px'
}));


export default BundleEditor;
