import { useState } from 'react';
import {
	IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import {withDialog, Dialog as ConfirmDialog} from 'muibox';
import { Coffeeshop } from '@models/Coffeeshop';
import { City, getCityFormattedByRef } from '@models/City';
import {DataGrid, GridColDef, deDE, GridValueGetterParams} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
	dialog: ConfirmDialog;
    coffeeshops: Coffeeshop[];
    cities: City[];
    handleEditCoffeeshop: (coffeeshop: Coffeeshop) => void;
    handleDeleteCoffeeshop: (coffeeshop: Coffeeshop) => void;
}

function CoffeeshopsTable(props: Props) {
    const [pageSize, setPageSize] = useState(20);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'city',
            headerName: 'Stadt',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                getCityFormattedByRef(params.row.city, props.cities),
        },
        {
            field: 'buttons',
            headerName: '',
            flex: 0.5,
            align: 'right',
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
						<IconButton
						    onClick={() => props.handleEditCoffeeshop(params.row)}
						    sx={{marginRight: 1}}
                            color='primary'
					    >
					        <EditIcon />
					    </IconButton>
						<IconButton
						    onClick={() => props.handleDeleteCoffeeshop(params.row)}
						    sx={{marginRight: 1}}
                            color='error'
					    >
					        <HighlightOffIcon />
					    </IconButton>
					</div>
                );
            }
        }
    ];

	return (
        <DataGridStyled
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.coffeeshops}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnSelector
            hideFooterSelectedRowCount
            autoHeight
      />
	);
}

// Styles
const DataGridStyled = styled(DataGrid)(({ theme }) => ({
	"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
     },
     marginBottom: theme.spacing(10)
}));

export default withDialog()(CoffeeshopsTable);
