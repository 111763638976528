import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DialogProvider } from 'muibox';

const tripsidesTheme = createTheme({
    palette: {
        primary: {
            main: '#FFA500',
            contrastText: 'white',
        },
    },
    typography: {
        fontFamily: 'Nunito'
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={tripsidesTheme}>
            <DialogProvider>
                <App />
            </DialogProvider>
        </ThemeProvider>
    </React.StrictMode>
);
