import { useState, useEffect } from 'react';
import {
	Fab,
	Paper,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import UserEditor from './UserEditor';
import { db } from '../../../firebase';
import { collection, onSnapshot } from "firebase/firestore";
import { User } from '@models/User';
import UsersTable from './UsersTable';


function Users() {
	const [users, setUsers] = useState<User[]>([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [userToEdit, setUserToEdit] = useState<User | null>(null);

	useEffect(() => {
		return onSnapshot(collection(db, 'users'), (snapshot) => {
			const users: User[] = []
			snapshot.forEach(document => {
				const documentData = document.data();
				const user: User = {
					id: document.id,
					email: documentData.email
				};
				users.push(user);
			});
			setUsers(users);
		});
	}, []);

	function handleNewUser(): void {
		setUserToEdit(null);
		setEditorOpen(true);
	}

	function handleEditUser(user: User): void {
		setUserToEdit(user);
		setEditorOpen(true);
	}

	function handleCloseEditor(): void {
		setEditorOpen(false);
	}

	return (
		<RootPaper>
			<Headline variant="h4">Benutzer</Headline>
			<UsersTable 
				users={users}
				handleEditUser={handleEditUser}
			/>
			<UserEditor
				editorOpen={editorOpen}
				closeHandler={handleCloseEditor}
				user={userToEdit}
			/>
			<AddButton
				color="primary"
				aria-label="add"
				onClick={() => {handleNewUser();}}>
				<AddIcon />
			</AddButton>
		</RootPaper>
	);
}

// Styles
const RootPaper = styled(Paper)(({ theme }) => ({
	marginLeft: 290,
	padding: theme.spacing(3)
}));

const AddButton = styled(Fab)(() => ({
	right: 30,
	bottom: 30,
	position: 'fixed'
}));

const Headline = styled(Typography)(({theme}) => ({
	marginBottom: theme.spacing(2)
}));


export default Users;
