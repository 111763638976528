import  {
	Alert,
	Avatar,
	Box,
	Button,
	CircularProgress,
	Container,
	TextField,
	Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";


function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	async function handleSubmit(event: any): Promise<void> {
		setLoading(true);
		event.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}

	return 	(
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
			<Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
			Sign in
			</Typography>
			<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
					error={error}
					onChange={(event) => setEmail(event.target.value)}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					error={error}
					onChange={(event) => setPassword(event.target.value)}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
				Sign In
				</Button>
				{loading &&
					<CircularProgress color="primary" sx={{ display: 'block', ml: 'auto', mr: 'auto' }} />
				}
				{error &&
					<Alert severity="error">Login failed — Please try again!</Alert>
				}
			</Box>
		</Box>
	</Container>
);

}
export default Login;
